<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_cus" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>รายการคนขาย</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-breadcrumbs :items="bc_items" class="grey lighten-3 pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <MenuTab @onTabChange="onTabChange" />

    <component v-bind:is="component" ref="com_tab" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Cus/Agent/Genaral/Datatable";
import CurrentBetTable from "@/components/Cus/Agent/CurrentBet/Datatable";
import CurFinancialTable from "@/components/Cus/Agent/CurFinancial/Datatable";
import AllFinancialTable from "@/components/Cus/Agent/AllFinancial/Datatable";
import PrizeAndPercentTable from "@/components/Cus/Agent/PrizeAndPercent/Datatable";
import MenuTab from "@/components/Cus/Agent/Layout/Menu_Tab";

export default {
  name: "Line_Data",

  mixins: [globalFunctionMixin],

  components: {
    GenaralTable,
    CurrentBetTable,
    CurFinancialTable,
    AllFinancialTable,
    PrizeAndPercentTable,
    MenuTab,
  },

  //get line name
  mounted() {
    this.ckLogin();

    this.bc_items[0].text = sessionStorage.getItem("host_id");
    this.bc_items[0].to = { path: "line_data" };
    this.bc_items[1].text = this.$route.query.line_id;
    //     const resolved = this.$router.resolve({
    //   name: 'agent_data',
    //   params: { line_id: this.$route.query.line_id }
    // })
    // this.bc_items[1].to=resolved;
    // this.bc_items[1].to = "/agent_data?line_id=" + this.$route.query.line_id;
  },
  data: () => ({
    component: "CurrentBetTable",

    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
    ],
  }),

  methods: {
    onTabChange(id) {
      switch (id) {
        case 0:
          this.component = CurrentBetTable;
          break;
        case 1:
          this.component = GenaralTable;
          break;
        case 2:
          this.component = CurFinancialTable;
          break;
        case 3:
          this.component = AllFinancialTable;
          break;
        case 4:
          this.component = PrizeAndPercentTable;
          break;
      }
      //alert(id)
    },
  },
};
</script>

<style></style>
