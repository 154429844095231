<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    persistent
    dense
    transition="dialog-bottom-transition"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded outlined color="green" v-bind="attrs" v-on="on">
        <v-icon left>
          mdi-filter-variant
        </v-icon>
        ตัวกรอง
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>ตัวกรอง</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="text--primary">
        <v-text-field
          label="รหัส"
          outlined
          dense
          hide-details
          class="mt-4"
        ></v-text-field>

        <v-text-field
          label="ชื่อ"
          outlined
          dense
          hide-details
          class="mt-4"
        ></v-text-field>

        <v-select
          :items="balance_items"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          label="มียอดค้างเก่า"
          class="mt-4"
        ></v-select>

        <v-select
          :items="waitingpage_items"
          outlined
          dense
          hide-details
          label="โพย"
          class="mt-4"
        ></v-select>
        <v-select
          :items="status_items"
          outlined
          dense
          hide-details
          label="สถานะ"
          class="mt-4"
        ></v-select>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="red" text>
          รีเซ็ต
        </v-btn>
        <v-btn color="green darken-4" text>
          ตกลง
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Menu_Tab",

  components: {
    // components,
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          alert("Onload");
        });
      }
    },
  },
  //mounted: {},
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return "60%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
      }
      return "50%";
    },
  },
  methods: {
  },
  data: () => ({
    dialog: false,
    balance_items: [
      { text: "ทั้งหมด", value: 0 },
      { text: "ที่มียอดค้างเก่า", value: 1 },
    ],
    waitingpage_items: [
      { text: "ทั้งหมด", value: 0 },
      { text: "โพยรอส่ง", value: 1 },
    ],
    status_items: [
      { text: "ทั้งหมด", value: 1 },
      { text: "ถูกระงับ", value: 2 },
    ],
  }),
};
</script>

<style scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  top: 0 !important;
  right: 0 !important;
  position: absolute !important;
  margin-top: 0;
  margin-right: 0;
  background-color: white;
}
</style>
