<template>
  <v-tabs v-model="active_tab" background-color="white" show-arrows>
    <v-tabs-slider color="red"></v-tabs-slider>

    <v-tab v-for="tab of tabs" :key="tab.id" @click="tab_change(tab.id)">
      {{ tab.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Menu_Tab",
//   created() {
//     alert(this.active_tab);
//   },
  methods: {
    tab_change(id) {
      this.$emit("onTabChange", id);
      //alert(this.active_tab);
    },
  },
  data: () => ({
    active_tab: 0,
    tabs: [
      { text: "งวดปัจจุบัน", id: 0 },
      { text: "ทั่วไป", id: 1 },
      { text: "บัญชีงวด", id: 2 },
      { text: "บัญชีรวม", id: 3 },
      { text: "อัตราจ่าย", id: 4 },
      { text: "บันทึก", id: 5 },
      { text: "ใบปะหน้า", id: 6 },
      { text: "ผู้ดูแล", id: 7 },
    ],
  }),
};
</script>

<style></style>
