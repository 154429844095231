<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="agent_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <AddData />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <FilterSheet ref="FilterSheet" />
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ agent_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'blue lighten-5': item.id === selectedId }"
          class="font-weight-black"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">{{ item.id }}</td>
          <!-- <td>{{ item.Nickname }}</td> -->
          <td v-if="item.Status == 0" class="red--text">
            {{ item.Nickname }}
          </td>
          <td v-if="item.Status == 1">{{ item.Nickname }}</td>

          <td class="teal--text" align="end">
            {{
              item.price_sum
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td class="purple--text" align="end">
            {{
              item.price_sum_loy
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td align="end" class="red--text">{{ item.non_send_page }}</td>

          <td align="center">{{ item.PhoneNumber1 }}</td>
          <td align="center">
            {{ item.PrizeRate3digit }}/{{ item.PrizeRate2digit }}
          </td>
          <td align="center">{{ item.LinePercent }}/{{ item.AgentPercent }}</td>
          <td align="end">
            {{
              item.Balance.toString().replace(
                /\B(?!\.\d*)(?=(\d{3})+(?!\d))/g,
                ","
              )
            }}
          </td>

          <td v-if="item.Status == 0" class="red--text" align="center">
            ระงับ
          </td>
          <td v-if="item.Status == 1" class="green--text" align="center">
            ปกติ
          </td>
        </tr>
      </template>
    </v-data-table>
    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Cus/Agent/CurrentBet/Popup_Menu";
import AddData from "@/components/Cus/Line/Genaral/Add_Data";
import FilterSheet from "@/components/Cus/Agent/CurrentBet/Filter";

export default {
  name: "CurrentBet_Datatable",

  components: {
    PopupMenu,
    AddData,
    FilterSheet,
  },
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    agent_data() {
      return this.$store.getters["agent/Agents"];
    },
    agent_count() {
      return this.$store.getters["agent/Agents"].length;
    },
  },
  methods: {
    //   SetLineID(LineID){
    //       this.LineID = LineID;
    //   },
    async fetch_table() {
      console.log("fetch_table");
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        ListType: "current_bet_list",
      };
      await this.$store.dispatch("agent/get_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.id;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.id;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    LineID: "",

    selectedId: -1,

    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "#",
        value: "LineID",
        width: "1%",
      },
      {
        text: "ชื่อ",
        value: "Nickname",
        align: "start",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดรวม",
        value: "PriceSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดลอย",
        value: "PriceLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "โพยรอส่ง",
        value: "WaittingPage",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
        sortable: true,
      },
      {
        text: "เบอร์โทร",
        value: "PhoneNumber1",
        align: "center",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "อัตราจ่าย",
        value: "Prize",
        align: "center",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "%",
        value: "Percent",
        align: "center",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ค้างเก่า",
        value: "OverdueBalance",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "สถานะ",
        value: "Status",
        align: "center",
        width: "10%",
        class: "brown lighten-5",
      },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(57, 137, 198) !important;
  color: whitesmoke;
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(57, 137, 198) !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
  font-size: 18px !important;
}

::v-deep table {
  background-color: #f5f5f5;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1.1em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
